<template>
  <div class="business">
    <el-popover
      placement="bottom-start"
      width="622"
      trigger="click"
      v-model="visible"
    >
      <el-button round slot="reference" v-if="type==2" :class="{clickbtn:htCnSonTags.hotel.length }" >
        {{ name }}
        <i :class="[visible ? 'el-icon-arrow-up' : 'el-icon-arrow-down']"></i>
      </el-button>
        <el-button round slot="reference" v-if="type==3" :class="{clickbtn: htCnSonTags.services.length }" >
        {{ name }}
        <i :class="[visible ? 'el-icon-arrow-up' : 'el-icon-arrow-down']"></i>
      </el-button>
      <!-- <div>
        <el-radio-group v-if="type == 0" v-model="reginRadio" @change="change">
          <el-radio
            class="radio-item"
            v-for="item in reginList"
            :key="item.id"
            :label="item.id"
            >{{ item.name }}</el-radio
          >
        </el-radio-group>
        <el-radio-group
          v-if="type == 1"
          v-model="businessRadio"
          @change="change"
        >
          <el-radio
            class="radio-item"
            v-for="item in businessList"
            :key="item.id"
            :label="item.id"
            >{{ item.name }}</el-radio
          >
        </el-radio-group>
      </div> -->
      <div v-if="type == 2">
        <el-tabs :tab-position="tabPosition" style="height: 200px">
          <el-tab-pane :label="textList[index]" v-for="(value, key, index) in moreOptions" :key="key">
            <el-checkbox-group  
              v-model="brandCheckList"
              class="check-box"
              @change="changeBrandCheckList(brandCheckList)"
            >
              <el-checkbox
               v-for="item in value" :key="item.value"
                :label="item.value"
                class="box-item"
                >{{ item.name }}</el-checkbox
              >
            </el-checkbox-group>
          </el-tab-pane>
        </el-tabs>
      </div>
      <div v-if="type == 3">
        <el-checkbox-group
          v-model="facilitiesList"
          @change="changeFacilitiesList"
          class="check-box"
        >
          <el-checkbox
            v-for="item in facilities"
            :key="item.value"
            :label="item.value"
            class="box-item"
            >{{ item.name }}</el-checkbox
          >
        </el-checkbox-group>
      </div>
    </el-popover>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex'
export default {
  name: "HtCity",
  props: {
    // 0是行政区，1是商圈,2是品牌，3是服务
    type: {
      type: [Number, String],
      default: 0,
      required: true,
    },
    cityId: {
      //传入的城市id
      type: String,
      default: "",
    },
    name: {
      //名字
      type: String,
      required: true,
    },
  },
  data() {
    return {
    checked:false,
     textList:["经济","舒适","高端","豪华"],
      reginList: [],
      reginRadio: "",
      businessList: [],
      businessRadio: "",
      changeRadio: "",
      visible: false,
      brandCheckList: [], //酒店选择之后的list数据
      facilitiesList: [], //设施服务选择之后的数据
      tabPosition: "left",
      moreOptions:{
        Economics: [
        //经济
        { name: "飘HOME", value: "39502", fitlerTypeByOne:"hotel",checked:false},
        { name: "如家", value: "32", fitlerTypeByOne:"hotel",checked:false},
        { name: "汉庭", value: "56", fitlerTypeByOne:"hotel",checked:false},
        { name: "7天", value: "53", fitlerTypeByOne:"hotel",checked:false},
        { name: "锦江之星", value: "34", fitlerTypeByOne:"hotel",checked:false},
        { name: "莫泰", value: "35", fitlerTypeByOne:"hotel",checked:false},
        { name: "速8", value: "41", fitlerTypeByOne:"hotel",checked:false},
        { name: "格林豪泰", value: "44", fitlerTypeByOne:"hotel",checked:false},
        { name: "布丁", value: "111", fitlerTypeByOne:"hotel",checked:false},
        { name: "尚客优", value: "84", fitlerTypeByOne:"hotel",checked:false},
        { name: "易佰连锁", value: "29305", fitlerTypeByOne:"hotel",checked:false},
        { name: "锐思特", value: "342", fitlerTypeByOne:"hotel",checked:false},
        { name: "驿捷", value: "39547", fitlerTypeByOne:"hotel",checked:false},
        { name: "欣燕都", value: "33", fitlerTypeByOne:"hotel",checked:false},
        { name: "格林联盟", value: "39408", fitlerTypeByOne:"hotel",checked:false},
        { name: "怡莱(华住)", value: "412", fitlerTypeByOne:"hotel",checked:false},
      ],
        comfortable: [
        //舒适
        { name: "维也纳", value: "97", fitlerTypeByOne:"hotel",checked:false},
        { name: "智选假日", value: "330", fitlerTypeByOne:"hotel",checked:false},
        { name: "锦江之星品尚", value: "39421", fitlerTypeByOne:"hotel",checked:false},
        { name: "全季", value: "29323", fitlerTypeByOne:"hotel",checked:false},
        { name: "时光漫步", value: "39494", fitlerTypeByOne:"hotel",checked:false},
      ],
        senior: [
        // 高端
        { name: "亚朵", value: "39438", fitlerTypeByOne:"hotel",checked:false},
        { name: "锦江", value: "7", fitlerTypeByOne:"hotel",checked:false},
        { name: "戴斯", value: "47", fitlerTypeByOne:"hotel",checked:false},
        { name: "华美达", value: "40", fitlerTypeByOne:"hotel",checked:false},
        { name: "假日", value: "29154", fitlerTypeByOne:"hotel",checked:false},
        { name: "首旅建国", value: "6", fitlerTypeByOne:"hotel",checked:false},
        { name: "金陵连锁", value: "39", fitlerTypeByOne:"hotel",checked:false},
        { name: "最佳西方", value: "31", fitlerTypeByOne:"hotel",checked:false},
        { name: "桔子", value: "65", fitlerTypeByOne:"hotel",checked:false},
        { name: "诺富特", value: "547", fitlerTypeByOne:"hotel",checked:false},
        { name: "美爵", value: "621", fitlerTypeByOne:"hotel",checked:false},
        { name: "四季", value: "104", fitlerTypeByOne:"hotel",checked:false},
        { name: "雅乐轩", value: "7494", fitlerTypeByOne:"hotel",checked:false},
        { name: "万怡", value: "432", fitlerTypeByOne:"hotel",checked:false},
      ],
        luxury: [
        // 豪华
        { name: "喜来登", value: "313", fitlerTypeByOne:"hotel",checked:false},
        { name: "皇冠假日", value: "316", fitlerTypeByOne:"hotel",checked:false},
        { name: "开元", value: "37", fitlerTypeByOne:"hotel",checked:false},
        { name: "洲际", value: "15", fitlerTypeByOne:"hotel",checked:false},
        { name: "希尔顿", value: "14", fitlerTypeByOne:"hotel",checked:false},
        { name: "香格里拉", value: "11", fitlerTypeByOne:"hotel",checked:false},
        { name: "威斯汀", value: "423", fitlerTypeByOne:"hotel",checked:false},
        { name: "凯宾斯基", value: "45", fitlerTypeByOne:"hotel",checked:false},
        { name: "铂尔曼", value: "436", fitlerTypeByOne:"hotel",checked:false},
        { name: "希尔顿逸林", value: "29155", fitlerTypeByOne:"hotel",checked:false},
        { name: "万豪", value: "13", fitlerTypeByOne:"hotel",checked:false},
        { name: "万丽", value: "393", fitlerTypeByOne:"hotel",checked:false},
        { name: "君悦", value: "422", fitlerTypeByOne:"hotel",checked:false},
        { name: "丽思卡尔顿", value: "488", fitlerTypeByOne:"hotel",checked:false},
        { name: "日航", value: "12", fitlerTypeByOne:"hotel",checked:false},
        { name: "丽笙", value: "6676", fitlerTypeByOne:"hotel",checked:false},
        { name: "柏悦", value: "739", fitlerTypeByOne:"hotel",checked:false},
      ],

      },
      // 设施服务
      facilities: [
        { name: "叫醒服务", value: "10", fitlerTypeByOne: "services" },
        { name: "行李寄存", value: "17", fitlerTypeByOne: "services" },
        { name: "会议设施", value: "208", fitlerTypeByOne: "services" },
        { name: "免费接机", value: "242", fitlerTypeByOne: "services" },
        { name: "收费接机", value: "269", fitlerTypeByOne: "services" },
        {
          name: "餐厅",
          value: "225,226,233,234,235",
          fitlerTypeByOne: "services",
        },
        { name: "健身中心", value: "160", fitlerTypeByOne: "services" },
        { name: "游泳池", value: "163,268", fitlerTypeByOne: "services" },
        { name: "商务中心", value: "207", fitlerTypeByOne: "services" },
        { name: "洗衣服务", value: "11", fitlerTypeByOne: "services" },
        { name: "SPA", value: "165", fitlerTypeByOne: "services" },
        { name: "无障碍设施", value: "222", fitlerTypeByOne: "services" },
      ],
    };
  },
    computed:{
        ...mapState({
            htCnParams:state =>state.htCn.htCnParams,
             htCnSonTags:state =>state.htCn.htCnSonTags,
        }),
  },
  mounted() {
    // this.getcity();
    // this.brandCheckList=thi
    // this.$nextTick(()=>{
    //     this.brandCheckList=this.htCnSonTags.hotel
    // })
  },
  watch: {
    // cityId() {
    //   this.getcity();
    // },
    htCnSonTags:{
        handler(val){
            let arr=val.hotel.map(item=>{
                return item.value
            })
           this.brandCheckList= arr //初始化赋值，避免页面刷新丢失
           let serverArr=val.services.map(item=>{
               return item.value
           })
           this.facilitiesList=serverArr
        },
        deep:true,
        immediate:true
    }
  },
  methods: {
        ...mapMutations({
            SET_HTCN_PARAMS:'htCn/SET_HTCN_PARAMS', // 设置查询参数
            SET_HTCN_SONNTAGS:'htCn/SET_HTCN_SONNTAGS',
            DELETE_HTCN_SONNTAGS:'htCn/DELETE_HTCN_SONNTAGS'
        }),
    change(val) {
      if (this.type == 0) {
        this.changeRadio = this.reginList.filter((item) => item.id == val);
        // type==0的时候,searchType=1
        this.businessRadio = "";
      } else if (this.type == 1) {
        this.changeRadio = this.businessList.filter((item) => item.id == val);
        this.reginRadio = "";
      }
      this.$emit("changeRadio", this.changeRadio, this.type);
      this.visible = false; //radio选择之后关闭
    },
    removeHotel(val){
        // 删除单项的品牌连锁
        //更新vuex
        this.DELETE_HTCN_SONNTAGS({hotel:val})
        this.SET_HTCN_PARAMS({brandIds:this.htCnSonTags.hotel.map(e=>e.value).join(",")})
        this.$emit('changeList')
    },
    removeServices(val){
        this.DELETE_HTCN_SONNTAGS({services:val})
        this.SET_HTCN_PARAMS({propertyIds:this.htCnSonTags.hotel.map(e=>e.value).join(",")})
        this.$emit('changeList')
    },
    changeBrandCheckList(val) {
        //  console.log(val,"val") //element ui 多选情况数据结构为数组对象的时候下无法设置默认值！！！！（踩了个大坑）
        // 只能自己做匹配处理了
        let arr=[]        
        for(let  i in this.moreOptions){
            this.moreOptions[i].map(item=>{
                val.map(e=>{
                    if(e==item.value){
                        arr.push(item)
                    }
                })
            })
        }
      this.SET_HTCN_SONNTAGS({hotel:arr})
      this.SET_HTCN_PARAMS({brandIds:this.htCnSonTags.hotel.map(e=>e.value).join(",")})
      this.$emit('changeList')
    },
    changeFacilitiesList(val) {
        let arr=[]
        this.facilities.map(item=>{
             val.map(e=>{
                    if(e==item.value){
                        arr.push(item)
                    }
                })
        })
      this.SET_HTCN_SONNTAGS({services:arr})
      this.SET_HTCN_PARAMS({propertyIds:this.htCnSonTags.services.map(e=>e.value).join(",")})
      this.$emit('changeList')
    },

    removeBusinessRadio() {
      this.businessRadio = "";
    },
    // removeReginRadio() {
    //   this.reginRadio = "";
    // },
    removeAll() {
      this.facilitiesList = [];
      this.brandCheckList = [];
    //   this.reginRadio = "";
    //   this.businessRadio = "";
      this.$emit('removeAll')
    },
    // removeRadio(){
    //   // 移除单项

    // },
	

    // getcity() { 没有使用
    //   this.$conn
    //     .getConn("staticData.geoLocation", { jsfile: true })({
    //       cityId: this.cityId,
    //       type: "1,2",
    //     })
    //     .then((res) => {
    //       if (res.data) {
    //         this.reginList = res.data[0].location;
    //         this.businessList = res.data[1].location;
    //       } else {
    //         return;
    //       }
    //     });
    // },
  },
};
</script>

<style scoped lang="scss">
.clickbtn {
  border: 1px solid #007aff;
  background-color: #007aff;
  color: #fff;
  & :hover {
    color: white;
  }
}

.box-item {
  width: 30%;
  margin: 5px;
}
.business {
  margin-right: 10px;
  .check-box {
    display: flex;
  }
}

/deep/ .el-radio-group {
  font-size: 33px;
  align-content: flex-start;
  flex-flow: row wrap;
  height: 300px;
  // overflow-x:auto;
  overflow-y: auto;
  .radio-item {
    width: 25%;
  }
}
</style>
